import './App.css';
import { React, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Head from './Head.js'
import RenderMenu from './Menu.js'

export default function PaymentMethods() {
    let { id } = useParams();

    function RenderPaymentMethods() {
        return(
            <div className="App-contacts App-text-paragraph">
                <u>Оплата банковской картой</u><br /><br />
                Для выбора оплаты парковки с помощью банковской карты на соответствующей странице необходимо нажать кнопку <b>Оплатить</b>. Оплата происходит через ПАО СБЕРБАНК.<br/><br/>

                Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может потребоваться ввод специального пароля.<br/><br/>

                Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.<br/><br/>

                <u>Возврат денежных средств</u><br /><br />

                Срок возврата денежных средств составляет 1 сутки с момента получения парковочного талона. Необходимо отправить заявку на адрес Технической поддержки с указанием времени произведения оплаты и государственного регистрационного номера автомобиля.<br/><br/>

                Возврат переведённых средств, производится на ваш банковский счёт в течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).<br/><br/>
                <div style={{"text-align": "center"}}>
                    <img src="/icons/all.png" style={{"max-width": "90%"}}/>
                </div>
            </div>
        );
    }

//    <img src="/icons/icons.png" width="300px" height="150px" className="App-icons" />

    function FinalHtml() {
        return (
            <div className="App-wrapper App-not-clickable">
              <Head />
                <div className="App-top-wrapper">
                    <div className="App-top">
                        <h1>QR <span className="App-text-orange">PARKING</span></h1>
                    </div>
                    <div style={{"width": "100%", "background-color": "#303c8c"}}>
                        <div style={{"padding-left": "10%", "padding-right": "10%", "padding-bottom": "10px"}}>
                            <RenderPaymentMethods />
                        </div>
                    </div>
                </div>
                {RenderMenu(id)}
            </div>
        );
    }
    return (<FinalHtml />);
}
