import './App.css';
import React, {useState, useEffect} from 'react';
import Head from './Head.js'
import RenderMenu from './Menu.js'
import Receipt from './Receipt.js'

export default function Paid() {
    const [data, setData] = useState({})
    const [receipt, setReceipt] = useState({})
    const [id, setId] = useState()
    const [ntries, setNtries] = useState(0)

    useEffect(async () => {
        const params = new URLSearchParams(window.location.search);
        setId(params.get('card_id'));
        let res;
        try {
            const resp = await fetch(`https://parking.horoshomall.ru/api/pay/?card_id=${params.get('card_id')}&orderId=${params.get('orderId')}`, {method: "GET"});
            res = await resp.json();
        } catch {
            res = {'error': 'Ошибка сервера'};
        }
        setData(res);
    }, []);

    useEffect(async () => {
        const params = new URLSearchParams(window.location.search);
        setTimeout(async function () {
            const resp2 = await fetch(`https://parking.horoshomall.ru/api/get_receipt/?orderId=${params.get('orderId')}`, {method: "GET"});
            var res2 = await resp2.json();
            if (res2['receipt']['receiptStatus'] === -1)
                setNtries(ntries + 1);
            setReceipt(res2)
        }, 5000);
    }, [ntries])

    let ShowStatus = () => {
        if (!data)
            return <React.Fragment>Проверка статуса оплаты</React.Fragment>
        else if (data['error'])
            return <React.Fragment>{data['error']}</React.Fragment>
        else
            return <React.Fragment>Оплата прошла успешно</React.Fragment>
    }

    return (
        <div className="App-wrapper">
            <Head/>
            <div className="App-top-wrapper">
                <div className="App-top">
                    <h1>QR <span className="App-text-orange App-not-clickable">PARKING</span></h1>
                    <p className="App-text-paragraph">
                        <h3 className="App-not-clickable">
                            <ShowStatus/>
                        </h3>
                    </p>
                </div>
                <div style={{"width": "100%", "background-color": "#303c8c"}}>
                    <div style={{"display": "flex", "align-items": "center", "justify-content": "center"}}>
                        {Receipt(receipt)}
                    </div>
                </div>
            </div>
            {RenderMenu(id)}
        </div>
    );
}
