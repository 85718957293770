import './App.css';
import { React, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Head from './Head.js'
import RenderMenu from './Menu.js'

export default function Contacts() {
    let { id } = useParams();

    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        height
      };
    }

    function useWindowDimensions() {
      const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

      useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      return windowDimensions;
    }
    const { height } = useWindowDimensions();
    useEffect(
        () => {
            const vh = height * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        [height]
    );

    function RenderContacts() {
        return(
            <div className="App-text-paragraph">
                <u>Контакты и реквизиты</u>:<br />
                Общество с ограниченной ответственностью “Торговый центр “Хорошо!”<br />
                Электронная почта: <a href="mailto:horoshoadm@mail.ru" className="App-link">horoshoadm@mail.ru</a><br />
                ИНН 7714417755 / КПП 771401001 / ОГРН 5177746164888.<br />
                Фактический / юридический адрес: <a href="https://yandex.ru/maps/-/CCUqEMwnpD" className="App-link">123007, г. Москва, Хорошёвское шоссе, д. 27.</a><br />
                Телефон: <a href="tel:+7 (495) 777-54-51" className="App-link">+7 (495) 777-54-51</a><br /><br />

                <u>Тех. поддержка</u>:<br />
                Электронная почта: <a href="mailto:support@accelstacks.com" className="App-link">support@accelstacks.com</a>
            </div>
        );
    }

    function FinalHtml() {
        return (
            <div className="App-wrapper">
              <Head />
                <div className="App-top-wrapper">
                    <div className="App-top">
                        <h1 className="App-not-clickable">QR <span className="App-text-orange">PARKING</span></h1>
                    </div>
                    <div style={{"width": "100%", "background-color": "#303c8c"}}>
                        <div style={{"padding-left": "10%", "padding-right": "10%", "padding-bottom": "3%"}}>
                            <RenderContacts />
                        </div>
                    </div>
                </div>
                {RenderMenu(id)}
            </div>
        );
    }
    return (<FinalHtml />);
}
