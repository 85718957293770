import './App.css';
import { React, useState, useEffect } from 'react';
import moment from 'moment';
import QRCode from 'qrcode';

export default function Receipt(receipt) {
    const [qr, setQr] = useState();
    function RenderQRCode(receipt) {
        QRCode.toDataURL(
            `t=${moment(new Date(receipt['receipt']['receipt_date_time'])).format("yyyyMMDDTHHmm")}&s=${(receipt['receipt']['amount_total']/100).toFixed(2)}&fn=${receipt['receipt']['fn_number']}&i=${receipt['receipt']['fiscal_document_number']}&fp=${receipt['receipt']['fiscal_document_attribute']}&n=1`
        ).then((setQr))
        return (<img src={qr} />);
    }
    if (typeof receipt !== 'undefined' && typeof receipt['receipt'] !== 'undefined' && receipt['receipt'] !== null && receipt['receipt']['shift_number']) {
        var rec = receipt['receipt']
        return (
            <div className="App-receipt">
                {rec['company_name']} < br/>< br/>
                ИНН: {rec['inn']} СНО: {rec['sno']}<br/>< br/>
                <b>{rec['url']}</b> <br/>< br/>
                {rec['address']} <br/>< br/>
                <h4> Приход </h4>
                Кассовый чек №{rec['fiscal_receipt_number']}
                <h4> {moment(new Date(rec['receipt_date_time'])).format("DD.MM.yyyy / HH:mm")} </h4>
                <p style={{"text-align": "left"}}>
                    Смена №{rec['shift_number']}:
                    <span style={{"float": "right"}}>
                        ₽
                    </span>
                </p>
                <hr/>
                <p style={{"text-align": "left"}}>
                    {rec['name']}:
                    <span style={{"float": "right"}}>
                        1.00 x {rec['amount_total'] / 100}
                    </span>
                </p>
                <p style={{"text-align": "right"}}>
                    = {rec['amount_total'] / 100}
                </p>
                <p style={{"text-align": "left", "color": "#828282"}}>
                    {rec['type']} <br/>< br/>
                    {rec['payment_type']} <br/>< br/>
                    {rec['nds_name']} <br/>< br/>
                </p>
                <hr/>
                <p style={{"text-align": "left"}}>
                    {rec['nds_full_name']}
                    <span style={{"float": "right"}}>
                        {Number(rec['amount_total'] / rec['nds']).toFixed(2)}
                    </span>
                </p>
                <p style={{"text-align": "left"}}>
                    <b>ИТОГО:</b>
                    <span style={{"float": "right"}}>
                        <b>= {rec['amount_total'] / 100}</b>
                    </span>
                </p>
                <p style={{"text-align": "left"}}>
                    Безналичными:
                    <span style={{"float": "right"}}>
                        = {rec['amount_total'] / 100}
                    </span>
                </p>
                <hr/>
                ФН: {rec['fn_number']}<br/><br/>
                ФД: {rec['fiscal_document_number']}<br/><br/>
                ФПД: {rec['fiscal_document_attribute']}<br/><br/>
                РН ККТ: {rec['ecr_registration_number']}
                <hr/>
                <p style={{"text-align": "left"}}>
                    Сайт ФНС
                    <span style={{"float": "right"}}>
                        {rec['fnsSite']}
                    </span>
                </p>
                <hr/>
                {RenderQRCode(receipt)}
            </div>
        );
    }
    if (typeof receipt !== 'undefined' && typeof receipt['receipt'] !== 'undefined' && receipt['receipt'] !== null) {
        var data = receipt['receipt'];
        return (
            <div>
                <div className="App-receipt">
                    {data['company_name']} < br/>< br/>
                    ИНН: {data['inn']} СНО: {data['sno']}<br/>< br/>
                    <b>{data['url']}</b> <br/>< br/>
                    {data['address']} <br/>< br/>
                    <h4> Приход </h4>
                    <h4> {moment(new Date(data['date'])).format("DD.MM.yyyy / HH:mm")} </h4>
                    <hr/>
                    <p style={{"text-align": "left"}}>
                        {data['name']}:
                        <span style={{"float": "right"}}>
                            1.00 x {data['amount']}
                        </span>
                    </p>
                    <p style={{"text-align": "right"}}>
                        = {data['amount']}
                    </p>
                    <p style={{"text-align": "left", "color": "#828282"}}>
                        {data['type']} <br/>< br/>
                        {data['payment_method']} <br/>< br/>
                        {data['nds_name']} <br/>< br/>
                    </p>
                    <hr/>
                    <p style={{"text-align": "left"}}>
                        {data['nds_full_name']}
                        <span style={{"float": "right"}}>
                            {data['nds']}
                        </span>
                    </p>
                    <p style={{"text-align": "left"}}>
                        <b>ИТОГО:</b>
                        <span style={{"float": "right"}}>
                            <b>= {data['amount']}</b>
                        </span>
                    </p>
                    <p style={{"text-align": "left"}}>
                        Безналичными:
                        <span style={{"float": "right"}}>
                            = {data['amount']}
                        </span>
                    </p>
                    <hr/>
                </div>
                <div className="App-top App-text-paragraph">
                    <div className="App-loader">
                        <span class="App-circle App-first-child"></span>
                        <span class="App-circle App-moving"></span>
                        <span class="App-circle App-last-child"></span>
                    </div>
                    Создается электронный чек
                </div>
            </div>
        );
    }
    else
        return (
            <div className="App-top App-text-paragraph">
                <div className="App-loader">
                    <span class="App-circle App-first-child"></span>
                    <span class="App-circle App-moving"></span>
                    <span class="App-circle App-last-child"></span>
                </div>
                Создается электронный чек
            </div>
        );
}
