import { React, useState } from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";

export default function RenderMenu(id) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMain = () => {
        setAnchorEl(null);
        window.location.href = `/payment/${id}`;
    };
    const handleContacts = () => {
        setAnchorEl(null);
        window.location.href = `/info/${id}`;
    };
    const handlePaymentMethods = () => {
        setAnchorEl(null);
        window.location.href = `/payment_methods/${id}`;
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
//        event.target.classList.toggle("change");
    };

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                className="App-menu-button"
            >
                <div>
                    <div className="bar1" />
                    <div className="bar2" />
                    <div className="bar3" />
                </div>
            </Button>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                className="App-menu-wrapper"
            >
                <MenuItem onClick={handleMain}>
                    Оплатить
                </MenuItem>
                <MenuItem onClick={handleContacts}>
                    Контакты
                </MenuItem>
                <MenuItem onClick={handlePaymentMethods}>
                    Способы оплаты
                </MenuItem>
            </Menu>
        </div>
    );
}


