import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Head from './Head.js';
import RenderMenu from './Menu.js';

export default function Info() {
  const [data, setData] = useState({});
  const [debt, setDebt] = useState(0);
  const [info, setInfo] = useState(false);
  const [n, setN] = useState(0);
  const [expired, setExpired] = useState(false);
  let btnRef = useRef();

  async function get(id) {
    const resp = await fetch(`https://parking.horoshomall.ru/api/get/${id}`, { method: 'GET' });
    try {
      var res = await resp.json();
      setData(res);
      setDebt(res['required_payment']);
      if (!res['required_payment']) btnRef.current.setAttribute('disabled', 'disabled');
    } catch {}
  }

  async function handleClick() {
    btnRef.current.setAttribute('disabled', 'disabled');
    const resp = await fetch(`https://parking.horoshomall.ru/api/add?card_id=${id}&payment=${debt}`);
    try {
      var res = await resp.json();
      if (res['url']) window.location.href = res['url'];
    } catch {}
  }

  function time_format(data) {
    if (data['time_left_hours'] === '00' && data['time_left_minutes'] === '15') return 'менее 15 мин';
    return `${data['time_left_hours']} ч ${data['time_left_minutes']} мин`;
  }

  let { id } = useParams();
  useEffect(() => {
    get(id);
    setTimeout(function () {
      if (n < 15) setN(n + 1);
      else setExpired(true);
    }, 20000);
  }, [n]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { height } = useWindowDimensions();
  useEffect(() => {
    const vh = height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height]);

  function clickInfo() {
    setInfo(!info);
  }

  function InfoButton() {
    return (
      <div>
        <link href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet" />
        <div class="info App-text-paragraph">
          <i class="icon-info-sign" onClick={clickInfo} />
        </div>
      </div>
    );
  }

  function ShowTime(data) {
    if (!data || !data['time_left_hours'] || !data['time_left_minutes']) return null;
    return (
      <div>
        {' '}
        До увеличения цены:
        <br />
        {time_format(data)} <br />
        <br />
      </div>
    );
  }

  function ShowPlate(plate) {
    if (!plate) return null;
    return (
      <div>
        {' '}
        Госномер: {plate} <br />
        <br />
      </div>
    );
  }

  function ShowEntryTime(time) {
    if (!time) return null;
    return (
      <div>
        {' '}
        Время въезда:
        <br />
        {time} <br />
        <br />
      </div>
    );
  }

  function ShowTariffInfo(tariff) {
    if (!tariff) return null;
    return (
      <div>
        {' '}
        Информация о тарифе:
        <br />
        {tariff}{' '}
      </div>
    );
  }

  function ShowInfo() {
    if (!info) return null;
    return (
      <div
        className="App-text-paragraph"
        style={{ 'font-size': '18px', 'margin-top': '20px', 'line-height': '1', width: '201px' }}
      >
        {ShowTime(data)}
        {ShowPlate(data['plate_entry'])}
        {ShowEntryTime(data['time_entry'])}
        {ShowTariffInfo(data['tariff_info'])}
      </div>
    );
  }

  let MainContent = () => {
    if (expired) return <React.Fragment>Для продолжения обновите страницу</React.Fragment>;
    if (!data || Object.keys(data).length === 0)
      return (
        <div className="App-loader">
          <span className="App-circle App-first-child"></span>
          <span className="App-circle App-moving"></span>
          <span className="App-circle App-last-child"></span>
        </div>
      );
    if (data['error']) return <React.Fragment>{data['error']}</React.Fragment>;
    return (
      <React.Fragment>
        <div style={{ 'flex-direction': 'row', display: 'flex', 'font-size': '24px' }}>
          Сумма к оплате <InfoButton />
          <br />
        </div>
        <span className="App-text-payment">{data['required_payment'] / 100} p.</span>
        <ShowInfo />
      </React.Fragment>
    );
  };

  let ButtonPay = () => {
    if (expired || !data || Object.keys(data).length === 0 || data['error']) return null;
    return (
      <div className="App-half-page">
        <div className="App-bottom">
          {/* <p style={{ fontSize: '12px', color: '#E9967A' }}>
            Оплата через сайт недоступна
            <br />
            до понедельника 19.06.2023. <br />
            <br /> Приносим извинения за неудобства.
          </p> */}
          <div>
            <button ref={btnRef} onClick={handleClick} className="App-button-card App-button-width">
              Оплатить
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="App-wrapper App-not-clickable">
      <Head />
      <div className="App-half-page">
        <div className="App-top-wrapper">
          <div className="App-top">
            <h1>
              QR <span className="App-text-orange">PARKING</span>
            </h1>
            <p className="App-text-paragraph">
              <MainContent />
            </p>
          </div>
        </div>
        {RenderMenu(id)}
      </div>
      <ButtonPay />
    </div>
  );
}
