import './App.css';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Head from './Head.js'
import RenderMenu from './Menu.js'

export default function Root() {
    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        height
      };
    }

    function useWindowDimensions() {
      const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

      useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      return windowDimensions;
    }
    const { height } = useWindowDimensions();
    useEffect(
        () => {
            const vh = height * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        [height]
    );

    function Final_html() {
        return (
            <div className="App-wrapper App-not-clickable">
              <Head />
              <div className="App-half-page">
                <div className="App-top-wrapper">
                    <div className="App-top">
                        <h1>QR <span className="App-text-orange">PARKING</span></h1>
                        <p className="App-text-paragraph" style={{"font-size": "32px"}}>
                            <b>:)</b>
                        </p>
                    </div>
                </div>
                {RenderMenu(0)}
              </div>
            </div>
        );
    }
    return (<div>{Final_html()}</div>);
}
