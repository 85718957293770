import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './index.css';
import Info from './App';
import Paid from './Paid';
import Root from './Root'
import Contacts from './Contacts'
import PaymentMethods from './PaymentMethods'
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Switch>
            <Route path="/payment/:id">
                <Info />
            </Route>
            <Route path="/paid/">
                <Paid />
            </Route>
            <Route path="/info/:id">
                <Contacts />
            </Route>
            <Route path="/payment_methods/:id">
                <PaymentMethods />
            </Route>
            <Route>
                <Root />
            </Route>
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
